<!--
 * @Author: cyy
 * @Date: 2021-11-26 16:19:46
 * @LastEditTime: 2022-08-31 10:48:10
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\commerce\main.vue
-->
<template>
  <div id="commerce">
    <template v-if="$route.name == 'commerce'">
      <div class="top">
        <p>
          功能定义：商品分知识商品和外部商品，知识商品一般指VIP课、训练营、电子资料等，外部商品则是各类主流电商平台的商品；
        </p>
        <p>
          使用场景：将您的商品添加到网校，推送到公开课直播间里，以实现直播带货效果；
        </p>
        <p>添加流程：添加商品->选择类型 ->上传封面->设置售价->添加完成</p>
        <p>带货流程：公开课推流->控制台->推送->选择商品->上链接</p>
        <p>
          温馨提醒：根据《中华人民共和国电子商务法》要求，在平台售卖实物商品需要拥有相关经营资质，
          并只允许售卖与资质对应的实物商品，否则会有法律风险。如果为实物商品，建议您上架到主流电商平台，再添加到网校。
        </p>
      </div>

      <div class="main">
        <div class="header">
          <el-button type="primary" @click="add">添加商品</el-button>

          <div class="flex-center">
            <el-select
              v-model="post.goods_class"
              style="width: 120px"
              size="medium"
              placeholder="请选择"
              class="mr20"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              v-model="post.goods_type"
              style="width: 120px"
              size="medium"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-input
              size="medium"
              v-model="post.name"
              class="ml20"
              style="width: 218px"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
        </div>

        <pagination2
          @complete="completeListDate"
          :option="post"
          url="/LiveGoods/goodsList"
          ref="childDialogBox"
          class="childDialogBox"
        >
          <template v-slot:default="{ tableData }">
            <el-table
              ref="tablelist"
              @select-all="selectAll"
              @selection-change="selectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column type="selection" width="43px"></el-table-column>

              <el-table-column label="商品名称">
                <template slot-scope="{ row }">
                  <div class="good_contain">
                    <div class="pic">
                      <el-image
                        class="img"
                        :src="row.cover_img | imageslim(5)"
                        fit="contain"
                        alt=""
                        lazy
                      />
                    </div>
                    <div class="name_price">
                      <div class="name" :title="row.name">{{ row.name }}</div>
                      <div class="price">
                        <span v-if="row.price">￥{{ row.price }}</span>
                        <span class="corss_price" v-if="row.cost_price">
                          ￥{{ row.cost_price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="类型">
                <template slot-scope="{ row }">
                  {{ row.goods_class | gettype2 }}
                </template>
              </el-table-column>

              <el-table-column label="平台">
                <template slot-scope="{ row }">
                  {{ row.goods_type | gettype }}
                </template>
              </el-table-column>

              <el-table-column label="操作" width="200px">
                <template slot-scope="{ row }">
                  <div class="btns">
                    <el-button type="text" @click="edit(row.live_goods_id)">
                      编辑
                    </el-button>
                    <span class="sp_dir">|</span>
                    <el-button type="text" @click="share(row)">分享</el-button>
                    <span class="sp_dir">|</span>
                    <el-button
                      v-if="row.goods_class == 2"
                      type="text"
                      @click="$store.dispatch('open', row.url)"
                    >
                      访问
                    </el-button>
                    <el-button
                      v-else
                      type="text"
                      @click="
                        $router.push({
                          path: '/commerce/commerceOder',
                          query: { id: row.live_goods_id },
                        })
                      "
                    >
                      订单
                    </el-button>
                    <span class="sp_dir">|</span>
                    <el-button type="text" @click="dels(row.live_goods_id)">
                      删除
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>

          <template #footer>
            <div class="footer flex-center">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checked"
                @change="boxChange($event)"
              ></el-checkbox>
              <span class="ml10">已选择{{ selectionVal.length }} 条</span>
              <el-button
                :disabled="selectionVal.length == 0"
                size="medium"
                class="ml20"
                @click="dels(undefined)"
                style="width: 130px"
              >
                批量删除
              </el-button>
            </div>
          </template>
        </pagination2>

        <!-- 添加编辑商品 -->
        <el-dialog
          :visible.sync="dialogVisible"
          class="dialogVisible"
          width="850px"
          @closed="close"
          :close-on-click-modal="false"
          :destroy-on-close="true"
        >
          <div slot="title" class="title">
            {{ isaddOrEdit == 1 ? '添加商品' : '编辑商品' }}
            <!-- <span class="tip">
              提示：目前仅支持添加外部商品，添加后可在直播间推送
            </span> -->
          </div>
          <div class="dialog_contain">
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              label-width="107px"
              class="demo-ruleForm"
              label-position="left"
            >
              <el-form-item
                label="商品封面"
                :required="true"
                label-position="left"
              >
                <div class="tu_contian">
                  <div class="left">
                    <img
                      v-if="ruleForm.cover_img"
                      :src="ruleForm.cover_img"
                      alt=""
                    />
                  </div>
                  <div class="select_pic">
                    <div class="size_tip">
                      建议尺寸600*600px以上正方形图片，2M以内，JPG、PNG格式
                    </div>

                    <el-button size="medium" style="width: 120px">
                      选择图片
                    </el-button>
                    <change-photoimg
                      :size="2"
                      @complete="completephoto"
                      class="changePhotoimg"
                    ></change-photoimg>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="商品名称" :required="true">
                <el-input
                  style="width: 617px"
                  v-model="ruleForm.name"
                  placeholder="请输入商品名称"
                  maxlength="16"
                  show-word-limit
                ></el-input>
              </el-form-item>

              <el-form-item label="商品类型" :required="true">
                <el-radio-group
                  v-model="ruleForm.goods_class"
                  :disabled="isaddOrEdit == 2"
                >
                  <el-radio label="1">知识商品</el-radio>
                  <el-radio label="2">外部商品</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                label="商品链接"
                :required="true"
                v-if="ruleForm.goods_class == 2"
              >
                <el-input
                  style="width: 617px"
                  v-model="ruleForm.url"
                  placeholder="请输入https开头的商品链接"
                  maxlength="500"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="商品平台"
                style="309px"
                :required="true"
                v-if="ruleForm.goods_class == 2"
              >
                <el-select
                  v-model="ruleForm.goods_type"
                  placeholder="请选择活动区域"
                  :required="true"
                >
                  <el-option label="淘宝" value="1"></el-option>
                  <el-option label="天猫" value="2"></el-option>
                  <el-option label="京东" value="3"></el-option>
                  <el-option label="其他" value="4"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="售价展示" :required="true">
                <div class="price_contain">
                  <span class="sp_1">
                    <el-input
                      @blur="getPriceValue"
                      ref="price"
                      :value="ruleForm.price"
                      @input="ruleForm.price = toNumber(arguments[0])"
                      class="mr10"
                      placeholder="请输入金额"
                    ></el-input>
                    元
                  </span>
                  <span class="sp_2">
                    <span class="sp_3">划线价</span>

                    <el-input
                      :value="ruleForm.cost_price"
                      @input="ruleForm.cost_price = toNumber(arguments[0])"
                      class="ml10 mr10"
                      placeholder="请输入金额"
                    ></el-input>
                    元
                  </span>
                </div>
                <huabeiIssue
                  v-if="ruleForm.goods_class == 1"
                  :price="endprice"
                  :is_installment_3.sync="ruleForm.is_installment_3"
                  :is_installment_6.sync="ruleForm.is_installment_6"
                  :is_installment_12.sync="ruleForm.is_installment_12"
                ></huabeiIssue>
              </el-form-item>

              <div class="price_tips" v-if="ruleForm.goods_class == 2">
                选填，仅用于直播间展示，实际价格为第三方平台商品的价格
              </div>

              <el-form-item label="购买按钮名称" :required="true">
                <el-input
                  style="width: 617px"
                  v-model="ruleForm.buy_button"
                  placeholder="请输入购买按钮名称"
                  maxlength="6"
                  show-word-limit
                ></el-input>
              </el-form-item>

              <el-form-item
                label="详情"
                :required="true"
                v-if="ruleForm.goods_class == 1"
              >
                <editor
                  v-if="dialogVisible"
                  ref="editor"
                  class="editor"
                  v-model="ruleForm.details"
                ></editor>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="medium">
              取 消
            </el-button>
            <el-button type="primary" @click="submit" size="medium">
              保存
            </el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="分享"
          :visible.sync="dialogVisible2"
          width="422px"
          :close-on-click-modal="false"
        >
          <span style="line-height: 22px">
            《{{ selectRow.name }}》，请使用手机微信打开
            {{ selectRow.url }}
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="dialogVisible2 = false"
              style="width: 116px"
              size="medium"
            >
              关闭
            </el-button>
            <el-button
              type="primary"
              size="medium"
              class="cp for-copy"
              :data-clipboard-text="
                `《${selectRow.name}》，请使用手机微信打开  ${selectRow.url}`
              "
            >
              复制文案+链接
            </el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import bg from '@/mixin/background'
import editor from '@cm/editor'
import huabeiIssue from '@cm/huabeiIssue'
import ClipboardJS from 'clipboard'
export default {
  name: 'commerce',
  mixins: [bg],
  data() {
    return {
      // 自定义全选框
      checked: false,
      // 未全部选中的状态
      isIndeterminate: false,
      // 当前选中的个数
      selectedNum: 0,
      // 当前列表数据的长度
      tableLength: 0,

      endprice: '',

      dialogVisible2: false,

      selectionVal: [],

      selectRow: {},

      post: {
        goods_type: '0',
        name: '',
        goods_class: '0',
      },

      // 1,添加 2，编辑
      isaddOrEdit: 1,

      ruleForm: {
        name: '',
        cover_img: '',
        url: '',
        cost_price: '',
        price: '',
        goods_type: '1',
        goods_class: '1',
        details: '',
        is_installment_3: '2',
        is_installment_6: '2',
        is_installment_12: '2',
        buy_button: '立即购买',
      },

      options: [
        {
          value: '0',
          label: '全部平台',
        },
        {
          value: '1',
          label: '淘宝',
        },
        {
          value: '2',
          label: '天猫',
        },
        {
          value: '3',
          label: '京东',
        },
        {
          value: '4',
          label: '其他',
        },
      ],

      options2: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '知识商品',
        },
        {
          value: '2',
          label: '外部商品',
        },
      ],

      dialogVisible: false,
    }
  },

  components: {
    changePhotoimg,
    editor,
    huabeiIssue,
  },

  created() {
    this.initCopy()
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '淘宝'
          break
        case 2:
          text = '天猫'
          break
        case 3:
          text = '京东'
          break
        case 4:
          text = '其他'
          break
        default:
          text = '-'
      }
      return text
    },

    gettype2(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '知识商品'
          break
        case 2:
          text = '外部商品'
          break
      }
      return text
    },
  },

  watch: {
    selectedNum(val) {
      if (val > 0) {
        if (val == this.tableLength) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.tableLength) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  methods: {
    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    // 获取列表数据
    completeListDate(val) {
      this.tableLength = val.data && val.data.length
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    },

    share(row) {
      this.selectRow = row
      this.dialogVisible2 = true
    },

    dels(id) {
      this.$confirm('是否确定删除已选商品？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/LiveGoods/delGoods',
            data: {
              live_goods_id: id
                ? [id]
                : this.selectionVal.map(item => item.live_goods_id),
            },
            callback: () => {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
              this.$refs.childDialogBox.reset()
            },
          })
        })
        .catch(() => {})
    },

    close() {
      this.ruleForm = {
        name: '',
        cover_img: '',
        url: '',
        cost_price: '',
        price: '',
        goods_type: '1',
        goods_class: '1',
        details: '',
        is_installment_3: '2',
        is_installment_6: '2',
        is_installment_12: '2',
        buy_button: '立即购买',
      }
    },

    selectionChange(val) {
      this.selectionVal = val
      this.selectedNum = val.length
    },

    edit(id) {
      this.dialogVisible = true
      this.isaddOrEdit = 2
      this.selectid = id
      this.$http({
        url: `/LiveGoods/setGoods?live_goods_id=${id}`,
        callback: ({ data }) => {
          this.ruleForm = data
          this.endprice = data.price
        },
      })
    },

    submit() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        url: this.ruleForm.url.trim(),
        name: this.ruleForm.name.trim(),
      })

      if (!this.ruleForm.cover_img) {
        this.$root.prompt('请选择商品封面')
        return
      }

      if (!this.ruleForm.name.trim()) {
        this.$root.prompt('请输入商品名称')
        return
      }

      if (this.ruleForm.goods_class == 2 && !this.ruleForm.url) {
        this.$root.prompt('请输入商品链接！')
        return
      }

      const reg = this.ruleForm.url.slice(0, 4)
      console.log(reg)
      if (this.ruleForm.goods_class == 2 && reg != 'http') {
        this.$root.prompt('链接格式有误！')
        return
      }

      if (!this.ruleForm.price) {
        this.$root.prompt('请输入商品售价')
        return
      }
      // if (!this.ruleForm.cost_price) {
      //   this.$root.prompt('请输入商品划线价')
      //   return
      // }
      if (!this.ruleForm.buy_button.trim()) {
        this.$root.prompt('请输入购买按钮名称')
        return
      }

      if (this.ruleForm.goods_class == 1 && !this.ruleForm.details) {
        this.$root.prompt('请输入详情')
        return
      }

      if (this.isaddOrEdit == 1) {
        this.$http({
          url: '/LiveGoods/addGoods',
          data: this.ruleForm,
          callback: () => {
            this.dialogVisible = false
            this.$refs.childDialogBox.reset()
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
          },
        })
      } else {
        this.$http({
          url: `/LiveGoods/setGoods`,
          data: _.assign({}, this.ruleForm, { live_goods_id: this.selectid }),
          callback: () => {
            this.dialogVisible = false
            this.$refs.childDialogBox.reset()
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
          },
        })
      }
    },

    // 添加图片
    completephoto(val) {
      this.ruleForm.cover_img = val
    },

    // 添加商品
    add() {
      this.isaddOrEdit = 1
      this.dialogVisible = true
    },

    getPriceValue() {
      this.endprice = this.$refs.price.value
    },
  },
}
</script>

<style lang="scss" scoped>
#commerce {
  margin: 20px;
  .top {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .main {
    padding: 20px;
    background: #fff;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
  ::v-deep .el-form-item {
    display: flex !important;
    // align-items: center !important;
    .el-form-item__content {
      margin-left: 30px !important;
    }
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .childDialogBox {
    .good_contain {
      display: flex;
      .pic {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-right: 10px;
        .img {
          object-position: 50%;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .name_price {
        flex: 1;
        overflow: hidden;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #333333;
        }
        .price {
          margin-top: 20px;
          font-size: 14px;
          color: #ff3535;
          .corss_price {
            margin-left: 10px;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .sp_dir {
        color: #1b9d97;
        margin: 0 10px;
      }
    }
    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
    }
  }
  ::v-deep .dialogVisible {
    & .el-dialog {
      height: 546px;
      overflow: hidden;
    }
    .el-dialog__footer {
      position: absolute;
      bottom: -9px;
      background: #fff;
      z-index: 10;
      width: 100%;
      box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07);
    }
    .el-dialog__body {
      padding: 20px;
      overflow: auto;
      height: calc(100% - 145px);
    }
    .el-button {
      width: 100px;
    }
    .title {
      font-size: 14px;
      color: #333333;
      .tip {
        font-size: 12px;
        color: #ff3535;
        margin-left: 16px;
      }
    }

    .dialog_contain {
      position: relative;
      overflow: auto;

      .price_tips {
        font-size: 12px;
        color: #ff3535;
        margin-left: 128px;
      }
      .tu_contian {
        display: flex;
        .left {
          width: 80px;
          height: 80px;
          background: #e3e3e3;
          margin-right: 20px;
          img {
            object-position: 50%;
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }
        .select_pic {
          position: relative;
          .size_tip {
            line-height: 13px;
            font-size: 13px;
            color: #333333;
            margin-bottom: 27px;
          }
          #changePhotoimg {
            bottom: 1px;
            position: absolute;
            width: 120px;
            height: 36px;
          }
        }
      }
      .price_contain {
        display: flex;
        .sp_1,
        .sp_2 {
          display: flex;
          align-items: center;
          margin-right: 72px;
        }
        .sp_2 {
          .sp_3 {
            width: 63px;
          }
        }
      }
    }
  }
}
</style>
